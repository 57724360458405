import $ from 'jquery'
import './animate'

import { isTouchDevice, domReady } from './helpers'

Promise.all([
  $.getJSON('php/logged-in.php') as unknown as Promise<boolean>,
  domReady
]).then(([loggedIn]) => {
  if (isTouchDevice()) {
    $('body').addClass('mobile')
  }

  if (loggedIn) {
    return import(/* webpackChunkName: "calendar" */ './calendar').then(
      () => {}
    )
  } else {
    const $loginInput = $('<input />').addClass('input').attr({
      type: 'password',
      id: 'login-input',
      autofocus: '',
      autocomplete: 'off'
    })

    const $loginButton = $('<button class="primary btn">Login</button>')

    const $content = $('<div />')
      .addClass('content')
      .append([$loginInput, $loginButton])

    const $bottom = $('<div />').addClass('bottom buttons mode')

    const $title = $('<a class="active">Passwort eingeben</a>')
    const $top = $('<div />').addClass('top mode').append($title)

    const $loginMain = $('<div />').addClass('login-main').appendTo('body')

    const $loginLoader = $('<span />')
      .addClass(
        'animated bounceIn loader throbber throbber_green throbber_large'
      )
      .appendTo($loginMain)

    const $loginBox = $('<form />')
      .attr('autocomplete', 'off')
      .addClass('login-box')
      .append([$top, $content, $bottom])
      .on('submit', function (e) {
        e.preventDefault()

        $loginInput.prop('disabled', true)
        $loginButton.prop('disabled', true)

        return $.post('php/logged-in.php', { pw: $loginInput.val() }).done(
          function (res) {
            res = JSON.parse(res)
            if (!res) {
              $loginInput.prop('disabled', false)
              $loginButton.prop('disabled', false)
              return $loginInput.ani('highlight-error')
            } else {
              $content.slideUp(500)
              $top.add($bottom).addClass('loading')
              $title
                .css('animation-name', '0.5s')
                .css('-webkit-animation-name', '0.5s')
                .addClass('animated bounceOut')
              return setTimeout(() => {
                $loginBox.css({
                  transform: 'none',
                  marginLeft: 'calc(-70px + 2.5rem)',
                  marginTop: -35,
                  width: 70,
                  height: 70,
                  borderRadius: '50%',
                  backgroundColor: '#f5f5f5'
                })

                $loginLoader.hide().css('z-index', 1)

                setTimeout(() => $loginLoader.show(), 0)
                setTimeout(() => {
                  $top.add($bottom).hide()

                  setTimeout(
                    () =>
                      $loginBox.ani('bounceOut').then(() => {
                        $loginBox.remove()
                        $loginMain.remove()
                        $loginLoader.remove()
                        return import(
                          /* webpackChunkName: "calendar" */ './calendar'
                        )
                      }),
                    0
                  )
                }, 700)
              }, 500)
            }
          }
        )
      })
      .appendTo($loginMain)

    return $loginBox.ani('bounceLogIn')
  }
})
