export function modulo(a: number, b: number) {
  return ((a % b) + b) % b
}

// @ts-ignore
export const isTouchDevice = () =>
  !!('ontouchstart' in window || window.navigator.maxTouchPoints)

export const romanize = (str: string) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ß/g, 's')

export const uuid = () =>
  (String(1e7) + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  )

interface AnimationSupport {
  supported: boolean
  prefix: string
  cssProperty: string
  onEnd: string
}

let animationSupportCache: AnimationSupport
export function animationSupport() {
  if (animationSupportCache != null) return animationSupportCache

  let animation = true,
    pfx = '',
    elm = document.body

  if (elm.style.animationName !== undefined) {
    animation = true
  }

  animationSupportCache = {
    supported: animation,
    prefix: pfx,
    cssProperty:
      pfx.length === 0 ? 'animation' : `-${pfx.toLowerCase()}-animation`,
    onEnd:
      pfx.length === 0 ? 'animationend' : pfx.toLowerCase() + 'AnimationEnd'
  }

  return animationSupportCache
}

/**
 * Replace HTML inside a string with entities
 */
export function escapeHTML(string: string) {
  return String(string)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

export const domReady = new Promise<void>(resolve => {
  if (
    document.readyState === 'interactive' ||
    document.readyState === 'complete'
  ) {
    resolve()
  } else {
    const done = () => {
      document.removeEventListener('DOMContentLoaded', done)
      resolve()
    }
    document.addEventListener('DOMContentLoaded', done)
  }
})
