import $ from 'jquery'
import { animationSupport } from './helpers'

export interface AniOptions {
  duration: number
  triggerEndOn: 'animationend' | 'time'
}

export interface Pre {
  hasAnimatedClass: boolean
  styles: string
}

export interface jqAni {
  (action: 'stop', triggerEnd: boolean): void
  (animation: string, options?: number | AniOptions): Promise<void>
  stop(): void
}

declare global {
  interface JQuery {
    ani: jqAni
  }
}

export class Ani {
  protected readonly supported: boolean
  protected readonly prefix: string
  protected readonly css: string
  protected readonly endEvent: string

  protected promise: Promise<JQuery>
  protected animation: string
  protected el: JQuery
  protected options: AniOptions
  protected triggerDone: (...args: any[]) => any = () => {}
  protected pre?: Pre

  public stop(triggerEnd = false) {
    if (!this.pre) return
    if (!this.pre.hasAnimatedClass) {
      this.el.removeClass('animated')
    }
    this.el.removeClass(this.animation)
    this.el.attr('data-styles', this.pre.styles)
    if (triggerEnd) {
      this.triggerDone()
    }
  }

  public constructor(
    el: JQuery,
    animation: string,
    options: boolean | number | Partial<AniOptions> = {}
  ) {
    const aniSupp = animationSupport()
    this.supported = aniSupp.supported
    this.prefix = aniSupp.prefix
    this.css = aniSupp.cssProperty
    this.endEvent = aniSupp.onEnd

    this.animation = animation

    this.el = el

    if (typeof options === 'number') {
      options = { duration: options }
    }

    if (typeof options === 'boolean') {
      options = { duration: 400 }
    }

    this.options = Object.assign(
      {
        duration: null,
        triggerEndOn: 'animationend'
      },
      options
    )

    // Init promise
    this.promise = new Promise<JQuery>(resolve => {
      this.triggerDone = () => resolve(this.el)
    })

    // Resolve instantly if no animations are supported
    if (!this.supported) {
      this.triggerDone()
      return
    }

    this.pre = {
      hasAnimatedClass: el.hasClass('animated'),
      styles: el.attr('data-styles')!
    }

    // Add classes & styles
    el.addClass(`animated ${this.animation}`)
    if (this.options.duration !== null) {
      el.css(`${this.css}-duration`, `${this.options.duration / 1000}s`)
    } else {
      this.options.duration = parseInt(el.css(`${this.css}-duration`)) * 1000
    }

    // Trigger end after time or event
    if (this.options.triggerEndOn === 'time') {
      setTimeout(() => {
        return this.stop(true)
      }, this.options.duration)
    } else {
      el.one(this.endEvent, () => this.stop(true))
    }
  }

  public getPromise() {
    return this.promise
  }
}

$.fn.ani = function (
  this: JQuery,
  animation,
  options: boolean | number | Partial<AniOptions> = {}
) {
  if (options == null) {
    options = {}
  }
  if (animation === 'stop') {
    this.each(function () {
      const el = $(this)
      if (!el.data('ani')) {
        return
      }
      return el.data('ani').stop(options === true)
    })
    return
  } else {
    const promises: Promise<JQuery>[] = []
    const els = this
    els.each(function () {
      const el = $(this)
      const ani = new Ani(el, animation, options)
      el.data('ani', ani)
      promises.push(ani.getPromise())
    })

    return Promise.all(promises).then(() => {
      els.removeData('ani')
    })
  }
} as jqAni

$.fn.ani.stop = function (this: JQuery) {
  return this.each(function () {
    const el = $(this)
    if (!el.data('ani')) {
      return
    }
    return el.data('ani').stop()
  })
}
